@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Myriad Pro Regular";
  src: local("Myriad Pro Regular"), url("./fonts/MyriadPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Myriad Pro Bold';
  src: local("Myriad Pro Bold"), url("./fonts/MyriadPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: 'Myriad Pro Semibold';
  src: local("Myriad Pro Semibold"), url(./fonts/MyriadPro-Semibold.otf) format("opentype");
}

@layer base {
  html {
    @apply text-neutral-100 h-[100%];
    font-family: "Myriad Pro Regular", sans-serif;
  }

  body {
    @apply h-[100%] flex flex-col;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-lg py-3 px-4 text-fs-9 font-medium text-neutral-100 bg-neutral-10 border-none ring-0 transition duration-200 focus:bg-neutral-30 focus:border-none focus:ring-0 placeholder:text-neutral-60 disabled:bg-neutral-40;
  }

  .disabled .css-olqui2-singleValue{
    @apply text-neutral-800;
  }

  [type='checkbox'] {
    @apply transition focus:ring-0 focus:ring-offset-0 w-5 h-5 rounded bg-neutral-30 border-neutral-30 cursor-pointer;
  }

  [type='checkbox']:checked,
  [type='checkbox']:checked:focus,
  [type='checkbox']:checked:hover {
    @apply bg-signature-2-500;
  }

  [type='radio'] {
    @apply transition focus:ring-0 focus:ring-offset-0 bg-neutral-30 w-6 h-6 border-neutral-30;
  }

  [type='radio']:checked,
  [type='radio']:checked:hover,
  [type='radio']:checked:focus {
    @apply bg-signature-2-500;
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  right: 2px;
}

input:checked ~ .backside {
  background-color: #1d4ed8 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Custom Scrollbar */
/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #212529;
  border-radius: 8px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #343a40;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* tinymce hard style  */
.tox-tinymce {
  border-radius: 8px !important;
  border: 1px solid #f5f7f9 !important;
}

.tox .tox-edit-area__iframe {
  background-color: #f5f7f9 !important;
}

.tox-statusbar {
  display: none !important;
}

.tox-toolbar__primary {
  background: none !important;
}

.tox .tox-editor-header {
  z-index: 0 !important;
}

@layer components {
  .custom-calendar .rmdp-wrapper {
    border: 1px solid #000000;
    box-shadow: 0 0 5px #000000;
  }

  .custom-calendar .rmdp-panel-body li {
    background-color: #000000;
    box-shadow: 0 0 2px #000000;
  }

  .custom-calendar .rmdp-week-day {
    @apply text-neutral-100 font-semibold;
  }

  .custom-calendar .rmdp-day.rmdp-deactive {
    @apply hover:bg-neutral-20 text-neutral-30 rounded;
  }

  .custom-calendar .rmdp-range {
    background-color: #000000;
    box-shadow: 0 0 3px #000000;
  }

  .custom-calendar .rmdp-arrow {
    border: solid #000000;
    border-width: 0 2px 2px 0;
  }

  .custom-calendar .rmdp-arrow-container:hover {
    background-color: #000000;
    box-shadow: 0 0 3px #000000;
  }

  .custom-calendar .rmdp-panel-body::-webkit-scrollbar-thumb {
    background: #000000 !important;
  }

  .custom-calendar .rmdp-day.rmdp-today span {
    @apply bg-primary-light-2 text-primary rounded;
  }

  .custom-calendar .rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid #000000;
  }

  .custom-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
    @apply bg-blue-600 text-white rounded;
  }

  .custom-calendar .rmdp-day:not(.rmdp-day-hidden) span:hover {
    @apply hover:bg-neutral-20 hover:text-black rounded;
  }

  .custom-calendar .b-deselect {
    color: #000000;
    background-color: white;
  }

  .custom-calendar .rmdp-action-button {
    color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button) {
    background-color: #000000;
  }

  .custom-calendar .rmdp-button:not(.rmdp-action-button):hover {
    background-color: #000000;
  }

  .galert-basic {
    border-width: 1px;
    border-style: solid;
  }
  .galert-solid, .galert-light {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
  }
}

.splash-screen {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f2f3f8;
}

.splash-screen img {
  margin-bottom: 30px;
}

.tooltip-custom {
  padding: 2px 6px !important;
  font-size: 0.625rem !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
}

.tooltip-descName {
  background-color: white !important;
  color: #212529 !important;
  padding: 1rem !important;
  width: 270px !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  letter-spacing: 0.25px !important;
  border: 1px solid #e9ecef;
  border-radius: 16px;
}

.tooltip-descName-arrow {
  border: 1px solid #dee2e6;
}

.faq-line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.from-card ::before{
  content: "";
  position: absolute;
  top: 25px;
  right: -15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #eaecf0;
  background-color: #F9FAFB;
  transform: rotate(45deg);
  border-width: 1px 1px 0 0 ;
}

.to-card ::before{
  content: "";
  position: absolute;
  top: 25px;
  left: -15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #eaecf0;
  background-color: white;
  transform: rotate(45deg);
  border-width: 1px 1px 0 0;
}